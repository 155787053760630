import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CaseData } from 'src/app/models/case-data.model';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() caseData: CaseData;
  day: string | number;
  hour: string | number;
  minute: string | number;
  second: string | number;
  firstPaymentPlanDueDate: string = null;

  countdownInterval: any;
  get showHardAmount(): boolean { return !this.caseData.is_payment_plan && this.caseData.currency === 'HUF'; }

  ngOnInit(): void {
    this.findFirstPP();

    this.countdown();
    this.countdownInterval = setInterval(this.countdown, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.countdownInterval);
  }

  countdown = () => {
    const today = moment();
    let end: moment.Moment;
    if (this.caseData.is_payment_plan) {
      end = moment(this.firstPaymentPlanDueDate);
    } else {
      end = moment(this.caseData.hard_collection_at);
    }
    if (!end) {
      this.day = 'NaN';
      this.hour = 'NaN';
      this.minute = 'NaN';
      this.second = 'NaN';
      return;
    }
    const timeLeft = moment.duration(end.diff(today));

    this.day = end.diff(today, 'days');
    this.hour = timeLeft.hours();
    this.minute = timeLeft.minutes();
    this.second = timeLeft.seconds();
  };

  findFirstPP() {
    if (!this.caseData.is_payment_plan) {
      return;
    }

    for (const pp of this.caseData.payment_plans) {
      if (pp.status === 'pending') {
        this.firstPaymentPlanDueDate = pp.dueDate;
        break;
      }
    }
  }
}
